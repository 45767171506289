<template>
	<div>
		<b-modal
			id="modal-adjustment-detail"
			cancel-variant="outline-secondary"
			:cancel-title="$t('Close')"
			ok-variant="primary"
			centered
			:title="$t('Adjustment transaction detail')"
			size="lg"
			body-class="p-2"
			@hidden="resetModal()"
		>
			<b-overlay
				:show="show"
				variant="transparent"
				no-wrap
			/>
			<label class="d-block h5 mb-1">{{ $t('Member details') }}</label>
			<b-table-simple
				responsive
				bordered
				table-class="table-narrow table-white-space table-highlight"
				class="mb-2"
			>
				<b-tbody>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Username') }}</b-td>
						<b-td>{{ adjustment.username }}</b-td>
						<b-td class="table-td-header">{{ $t('Full Name') }}</b-td>
						<b-td>{{ adjustment.fullName }}</b-td>
						<b-td class="table-td-header">{{ $t('Currency') }}</b-td>
						<b-td>{{ adjustment.currency }}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<label class="d-block h5 mb-1">{{ $t('Adjustment detail') }}</label>
			<b-table-simple
				responsive
				bordered
				table-class="table-narrow table-white-space table-highlight"
				class="mb-2"
			>
				<b-tbody>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Transaction id') }}</b-td>
						<b-td>{{ adjustment.transactionId }}</b-td>
						<b-td class="table-td-header">{{ $t('Adjustment type') }}</b-td>
						<b-td>{{ adjustment.adjustmentType }}</b-td>
					</b-tr>
					<b-tr>
						<b-td class="table-td-header">Batch Adjustment Name</b-td>
						<b-td>{{ adjustment.batch_adjustment_name }}</b-td>
						<b-td class="table-td-header">{{ $t('Amount') }}</b-td>
						<b-td>{{ adjustment.amount }}</b-td>
					</b-tr>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Created at') }}</b-td>
						<b-td>{{ adjustment.created_at }}</b-td>
						<b-td class="table-td-header">{{ $t('Updated at') }}</b-td>
						<b-td>{{ adjustment.updated_at }}</b-td>
					</b-tr>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Remark') }}</b-td>
						<b-td colspan="3">{{ adjustment.reason }}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<!-- Transaction History -->
			<txn-logs
				:transaction-id.sync="transactionId"
			/>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BOverlay, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge, BTableSimple, BTr, BTh, BTd, BTbody,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import { formatDateTime, resolveVBank, numberFormat, resolveCurrency } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import TxnLogs from '@/views/payments/txn-logs/TxnLogs.vue'

export default {
	components: {
		TxnLogs,
		BOverlay, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		BTableSimple, BTr, BTd, BTh, BTbody,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		adjustmentData: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			transactionId: null,
			adjustment: {
				username: '',
				fullName: '',
				currency: '',
				transactionId: '',
				adjustmentType: '',
				batch_adjustment_name: '',
				amount: '',
				created_at: '',
				updated_at: '',
				reason: '',
				transaction_history: [],
			},
			show: false,
		}
	},
	watch: {
		adjustmentData: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.transactionId = newVal.ref
					this.adjustment = {
						username: newVal.user ? newVal.user.username : '-',
						fullName: newVal.user ? newVal.user.name : '-',
						currency: newVal.user ? newVal.user.currency : 'VND',
						transactionId: newVal.ref,
						adjustmentType: this.resolveType(newVal.type),
						batch_adjustment_name: '-',
						amount: newVal.amount / 1000,
						created_at: formatDateTime(newVal.created_at).replace('.000000Z', ''),
						updated_at: formatDateTime(newVal.updated_at).replace('.000000Z', ''),
						reason: newVal.note,
						transaction_history: [],
					}
				}
			}
		}
	},
	methods: {
		resetModal() {
			this.$emit('update:adjustmentData', null)
			this.adjustment = {}
		},
	},
	setup() {

		const resolveType = type => {
			if (type === 1) return 'Credit'
			if (type === 2) return 'Debit'
			return 'None'
		}

		return {
			formatDateTime,
			resolveVBank,
			numberFormat,
			resolveCurrency,
			resolveType,
		}
	},
}
</script>
<style lang="scss" scoped>
table.table-narrow td {
	padding: 10px !important;
}
.dark-layout .table-highlight .table-td-header {
    background-color: #343d55 !important;
}
.table-highlight td.table-td-header {
    background-color: #f3f2f7;
}
</style>>